/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;
  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;
  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
  /* Remove excess padding and border in Firefox 4+ */ }
  button::-moz-focus-inner {
    border: 0;
    padding: 0; }

body {
  font-family: "Ideal Sans A", "Ideal Sans B", sans-serif;
  font-weight: 400;
  color: #606060;
  font-size: 1.125rem;
  line-height: 1.5; }
  .nav__link, .footer {
    font-size: 0.9375rem; }

.banner__title, .h1, .page-title__title, .h2, .footer__title, .text-content h1, .text-content h2, .text-content h3, .h3, .text-content h4, .h4, .text-content h5, .h5, .text-content h6, .h6 {
  font-family: "Ideal Sans A", "Ideal Sans B", sans-serif;
  font-weight: 600;
  line-height: 1; }

.banner__title, .h1 {
  font-size: 2.625rem; }
  @media (min-width: 48.0625rem) {
    .banner__title, .h1 {
      font-size: 4rem; } }

.page-title__title, .h2 {
  font-size: 2.25rem;
  margin-bottom: 1.25rem; }
  @media (min-width: 48.0625rem) {
    .page-title__title, .h2 {
      margin-bottom: 1.875rem;
      font-size: 3.125rem; } }

.footer__title, .text-content h1, .text-content h2, .text-content h3, .h3 {
  font-size: 1.5rem; }

.text-content h4, .h4 {
  font-size: 1.25rem; }

.text-content a {
  color: #087CA7; }
  .text-content a:hover, .text-content a:visited, .text-content a:focus {
    color: #10223E; }

.text-content ul li {
  margin-bottom: 1.25rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .text-content ul li:last-child {
    margin-bottom: 0; }
  .text-content ul li:before {
    content: '';
    width: 0;
    height: 0;
    border-top: 0.5625rem solid transparent;
    border-bottom: 0.5625rem solid transparent;
    border-left: 0.6875rem solid #FEA82F;
    margin-right: 1.25rem;
    margin-top: 0.5625rem; }

.text-content ul {
  display: block;
  list-style-type: disc;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  padding-left: 2.5rem; }
  .text-content ul li ul {
    padding-left: 1.25rem; }
  .text-content ul li:before {
    margin-top: 0.75rem;
    width: 4px;
    height: 4px;
    background-color: #606060;
    border: none;
    border-radius: 100%; }

.text-content ol {
  counter-reset: counter;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  padding-left: 40px; }
  .text-content ol li {
    margin-bottom: 1.25rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    counter-increment: counter; }
    .text-content ol li ul {
      padding-left: 1.25rem; }
    .text-content ol li:last-child {
      margin-bottom: 0; }
    .text-content ol li:before {
      content: counter(counter) ". ";
      width: 0;
      height: 0;
      color: #606060;
      font-weight: inherit;
      margin-right: 1.25rem; }

.container {
  max-width: calc(77.5rem + 5%);
  margin: 0 auto;
  padding: 0 5%; }
  .container--small {
    max-width: calc(66.5625rem + 5%); }

.text-content a, .header__toggle:before, .header__toggle:after, .nav__link, .footer__link {
  -webkit-transition: all .2s ease;
  transition: all .2s ease; }

.footer__title, .text-content h1, .banner__title, .page-title__title {
  position: relative; }
  .footer__title:after, .text-content h1:after, .banner__title:after, .page-title__title:after {
    content: '';
    height: 2px;
    background-color: #FEA82F;
    display: block; }
  .footer__title:after, .text-content h1:after {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 3.125rem; }
  .banner__title:after, .page-title__title:after {
    margin-top: 15px;
    margin-bottom: 15px;
    width: 6.25rem; }

.row {
  display: block;
  margin-bottom: 5rem; }
  @media (min-width: 48.0625rem) {
    .row {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; } }
  .row .row {
    margin-bottom: 2.5rem; }
  .row:last-child {
    margin-bottom: 0; }

.col {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin-bottom: 2.5rem;
  position: relative; }
  @media (min-width: 48.0625rem) {
    .col .breakout {
      width: calc(50vw - 5rem + 6%);
      max-width: calc(50vw - 5rem + 6%);
      margin-right: calc(-50vw - 5rem); } }
  @media (min-width: 48.0625rem) {
    .col {
      margin-right: 5rem;
      margin-bottom: 0; } }
  @media (min-width: 48.0625rem) {
    .col .col {
      margin-right: 2.5rem; } }
  .col:last-child {
    margin-right: 0; }
  @media (min-width: 48.0625rem) {
    .col--1 {
      -ms-flex-preferred-size: 8.33333%;
          flex-basis: 8.33333%;
      max-width: 8.33333%; } }
  @media (min-width: 48.0625rem) {
    .col--2 {
      -ms-flex-preferred-size: 16.66667%;
          flex-basis: 16.66667%;
      max-width: 16.66667%; } }
  @media (min-width: 48.0625rem) {
    .col--3 {
      -ms-flex-preferred-size: 25%;
          flex-basis: 25%;
      max-width: 25%; } }
  @media (min-width: 48.0625rem) {
    .col--4 {
      -ms-flex-preferred-size: 33.33333%;
          flex-basis: 33.33333%;
      max-width: 33.33333%; } }
  @media (min-width: 48.0625rem) {
    .col--5 {
      -ms-flex-preferred-size: 41.66667%;
          flex-basis: 41.66667%;
      max-width: 41.66667%; } }
  @media (min-width: 48.0625rem) {
    .col--6 {
      -ms-flex-preferred-size: 50%;
          flex-basis: 50%;
      max-width: 50%; } }
  @media (min-width: 48.0625rem) {
    .col--7 {
      -ms-flex-preferred-size: 58.33333%;
          flex-basis: 58.33333%;
      max-width: 58.33333%; } }
  @media (min-width: 48.0625rem) {
    .col--8 {
      -ms-flex-preferred-size: 66.66667%;
          flex-basis: 66.66667%;
      max-width: 66.66667%; } }
  @media (min-width: 48.0625rem) {
    .col--9 {
      -ms-flex-preferred-size: 75%;
          flex-basis: 75%;
      max-width: 75%; } }
  @media (min-width: 48.0625rem) {
    .col--10 {
      -ms-flex-preferred-size: 83.33333%;
          flex-basis: 83.33333%;
      max-width: 83.33333%; } }
  @media (min-width: 48.0625rem) {
    .col--11 {
      -ms-flex-preferred-size: 91.66667%;
          flex-basis: 91.66667%;
      max-width: 91.66667%; } }
  @media (min-width: 48.0625rem) {
    .col--12 {
      -ms-flex-preferred-size: 100%;
          flex-basis: 100%;
      max-width: 100%; } }

html {
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

body {
  -webkit-font-smoothing: antialiased; }

.section {
  padding: 3.75rem 0; }
  @media (min-width: 48.0625rem) {
    .section {
      padding: 7.5rem 0; } }
  .section--topo {
    background-image: url(/assets/images/topography.png);
    background-size: 100%;
    background-position: center;
    background-repeat: repeat; }

a {
  text-decoration: none; }

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
          box-sizing: inherit; }

img {
  max-width: 100%;
  height: auto; }

.dark {
  color: #10223E; }

.page-content {
  padding: 3.75rem 0; }

.responsive-embed {
  position: relative;
  padding-bottom: 56.25%; }
  .responsive-embed iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%; }

main {
  margin-top: 6.25rem; }
  @media (min-width: 80rem) {
    main {
      margin-top: 0; } }

.header {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 999;
  background-color: #ffffff;
  height: 6.25rem; }
  @media (min-width: 80rem) {
    .header {
      position: relative;
      height: auto;
      overflow: hidden; } }
  .header__main {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -ms-flex-negative: 1;
        flex-shrink: 1; }
  .header__topo {
    display: none;
    pointer-events: none; }
    @media (min-width: 80rem) {
      .header__topo {
        display: block;
        position: absolute;
        top: -400px;
        right: 0;
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
        opacity: .15; } }
  .header__toggle {
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: 600;
    position: relative;
    width: 50px;
    height: 50px;
    padding: 0.625rem;
    background-color: #087CA7;
    color: #ffffff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    @media (min-width: 80rem) {
      .header__toggle {
        display: none; } }
    .header__toggle:before, .header__toggle:after {
      content: '';
      height: 2px;
      width: 100%;
      position: relative;
      background-color: #ffffff;
      margin-bottom: 0.3125rem; }
      .header--open .header__toggle:before, .header--open .header__toggle:after {
        width: 1.5625rem; }
    .header__toggle:before {
      -webkit-box-ordinal-group: 0;
          -ms-flex-order: -1;
              order: -1; }
      .header--open .header__toggle:before {
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
        top: -0.25rem; }
    .header__toggle:after {
      -webkit-box-ordinal-group: -1;
          -ms-flex-order: -2;
              order: -2; }
      .header--open .header__toggle:after {
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
        top: 0.25rem; }
  .header__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(16, 34, 62, 0.9);
    z-index: -1;
    display: none; }
    .header--open .header__overlay {
      display: block;
      pointer-events: none; }
      @media (min-width: 80rem) {
        .header--open .header__overlay {
          display: none; } }
  .header__logo {
    padding: 1.25rem 0;
    display: block;
    width: 80%;
    max-width: 300px; }
  .header__container {
    background-color: #ffffff; }
    @media (min-width: 80rem) {
      .header__container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center; } }

.nav {
  display: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
  padding: 1.25rem 0; }
  @media (min-width: 80rem) {
    .nav {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      border-top: none; } }
  .header--open .nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
  @media (min-width: 80rem) {
    .nav__main {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: end;
          -ms-flex-pack: end;
              justify-content: flex-end; } }
  .nav__subnav {
    border-top: 1px solid #DFDFDF;
    padding-top: 0.9375rem; }
    @media (min-width: 80rem) {
      .nav__subnav {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        border-top: none;
        -webkit-box-pack: end;
            -ms-flex-pack: end;
                justify-content: flex-end;
        padding-top: 0;
        margin-bottom: 0.3125rem; } }
    .nav__subnav .nav__item {
      margin-bottom: 0.3125rem; }
    .nav__subnav .nav__link {
      color: #606060; }
  .nav__item {
    margin-bottom: 0.9375rem; }
    .nav__item--has-children {
      position: relative; }
      .nav__item--has-children ul {
        display: none;
        margin-top: 0.625rem;
        padding-top: 0.3125rem;
        border-top: 1px solid #DFDFDF;
        border-bottom: 1px solid #DFDFDF; }
      .nav__item--has-children li {
        text-indent: 1.25rem; }
      .nav__item--has-children:after {
        content: '';
        position: absolute;
        top: 0.625rem;
        right: 0;
        width: 0;
        height: 0;
        border-right: 0.4375rem solid transparent;
        border-left: 0.4375rem solid transparent;
        border-top: 0.5625rem solid #FEA82F;
        border-bottom: none; }
        @media (min-width: 80rem) {
          .nav__item--has-children:after {
            display: none; } }
      .nav__item--has-children.nav__item--active ul {
        display: block; }
      .nav__item--has-children.nav__item--active:after {
        border-bottom: 0.5625rem solid #FEA82F;
        border-top: none; }
    @media (min-width: 80rem) {
      .nav__item {
        margin-bottom: 0;
        margin-left: 1.875rem; } }
  .nav__link {
    color: #10223E;
    font-weight: 600; }
    .nav__link:hover {
      color: #087CA7; }

.search {
  background-color: #087CA7;
  height: 1.875rem; }

.footer {
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(50%, white), color-stop(70%, rgba(255, 255, 255, 0))), url(/assets/images/footer-bg.jpg);
  background-image: linear-gradient(to bottom, white 50%, rgba(255, 255, 255, 0) 70%), url(/assets/images/footer-bg.jpg);
  background-position: bottom center;
  background-size: auto;
  background-repeat: no-repeat;
  padding-top: 3.75rem;
  padding-bottom: 25rem;
  border-top: 1px solid #DFDFDF; }
  @media (min-width: 48.0625rem) {
    .footer {
      background-position: top center;
      background-size: cover;
      background-image: url(/assets/images/footer-bg.jpg); } }
  .footer__title {
    line-height: 1;
    color: #10223E; }
  .footer__item {
    margin-bottom: 0.625rem; }
  .footer__link {
    color: #606060; }
    .footer__link:hover {
      color: #087CA7; }
  .footer__text {
    line-height: 1.85; }
  .footer__address {
    line-height: 1.85; }
  .footer .social {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 0.625rem; }
    .footer .social__item {
      margin-right: 0.625rem; }
    .footer .social__link {
      width: 30px;
      height: 30px;
      text-indent: -9999px;
      display: block;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center; }
      .footer .social__link--facebook {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzEiIGhlaWdodD0iMzAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNS4yMzggMEM2LjgyMiAwIDAgNi42NTQgMCAxNC44NjRzNi44MjIgMTQuODYzIDE1LjIzOCAxNC44NjMgMTUuMjM5LTYuNjU0IDE1LjIzOS0xNC44NjNDMzAuNDc3IDYuNjU0IDIzLjY1NCAwIDE1LjIzOCAwem00LjUxIDguNzQ1aC0yLjEzYy0uNzUxIDAtLjkwOC4zLS45MDggMS4wNTl2MS44MzNoMy4wMzhsLS4yOTIgMy4yMThIMTYuNzF2OS42MTNoLTMuOTM1di05LjU3M2gtMi4wNDZ2LTMuMjU4aDIuMDQ2VjkuMDdjMC0yLjQwOSAxLjMyLTMuNjY2IDQuMjQ5LTMuNjY2aDIuNzI0djMuMzR6IiBmaWxsPSIjMDg3Q0E3Ii8+PC9zdmc+); }
      .footer .social__link--twitter {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzEiIGhlaWdodD0iMzAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNS40MzkgMEM3LjAyMyAwIC4yIDYuNjU0LjIgMTQuODY0UzcuMDIzIDI5LjcyNyAxNS40NCAyOS43MjdzMTUuMjM4LTYuNjU0IDE1LjIzOC0xNC44NjNDMzAuNjc3IDYuNjU0IDIzLjg1NSAwIDE1LjQzOSAwem03LjY2MyAxMi4zMDNjLjIyNCA0Ljg0OS0zLjQ4MyAxMC4yNTUtMTAuMDQ2IDEwLjI1NUExMC4xOSAxMC4xOSAwIDAxNy42NCAyMS4wMWE3LjIzNCA3LjIzNCAwIDAwNS4yMzItMS40MjhjLTEuNTQ2LS4wMjgtMi44NTItMS4wMjQtMy4zLTIuMzk0YTMuNjI0IDMuNjI0IDAgMDAxLjU5NC0uMDU5Yy0xLjY5OS0uMzMzLTIuODcyLTEuODI2LTIuODM0LTMuNDI0YTMuNTkgMy41OSAwIDAwMS42MDEuNDMxYy0xLjU3NC0xLjAyNS0yLjAyLTMuMDUzLTEuMDk0LTQuNjAyYTEwLjEyIDEwLjEyIDAgMDA3LjI4NSAzLjYwMmMtLjUxNi0yLjE1NyAxLjE2Mi00LjIzMyAzLjQ0My00LjIzM2EzLjU3IDMuNTcgMCAwMTIuNTggMS4wODggNy4xOCA3LjE4IDAgMDAyLjI0My0uODM2IDMuNDcgMy40NyAwIDAxLTEuNTUzIDEuOTA3IDcuMTk0IDcuMTk0IDAgMDAyLjAzLS41NDMgNy4wODcgNy4wODcgMCAwMS0xLjc2NCAxLjc4NXoiIGZpbGw9IiMwODdDQTciLz48L3N2Zz4=); }
      .footer .social__link--instagram {
        background-image: url(/assets/images/instagram.svg); }

.text-content h1, .text-content h2, .text-content h3, .text-content h4, .text-content h5, .text-content h6 {
  margin: 2.5rem 0 1.25rem 0; }
  .text-content h1:first-child, .text-content h2:first-child, .text-content h3:first-child, .text-content h4:first-child, .text-content h5:first-child, .text-content h6:first-child {
    margin: 0 0 1.25rem 0; }

.text-content ul {
  margin-bottom: 1.25rem; }

.text-content ol {
  margin-bottom: 1.25rem; }

.text-content img {
  width: 100%;
  height: auto; }

.text-content p {
  margin-bottom: 1.25rem; }

.banner {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #10223E;
  min-height: 15.625rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #ffffff;
  position: relative; }
  .banner__content {
    width: 100%; }
  .banner__topo {
    position: absolute;
    top: -18.75rem;
    left: -8.125rem;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    opacity: .15;
    color: #ffffff; }

.page-title__title {
  margin-top: 3.75rem;
  color: #10223E; }
